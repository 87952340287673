//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonAction from '@/shared/components/ButtonAction.vue';
import SurveySlide from './Survey_Slide.vue';
import randomizeOptions from '../../utils/surveryHelpers';

export default {
  name: 'Survey_SlideList',
  components: {
    ButtonAction,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getEventData(option) {
      return {
        id: `${option.id}`,
        others: this.slide.others,
        attribution: option.attribution,
        followup: option.followup,
        value: option.image ? [`${this.slide.others}, ${option.id}`] : []
      };
    },
  },
  computed: {
    getSurveryOptions() {
      return randomizeOptions(this.slide);
    },
  },
};
