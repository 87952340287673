//
//
//
//
//
//
//
//
//


import ButtonRouter from '@/shared/components/ButtonRouter.vue';
import SurveySlide from './Survey_Slide.vue';

export default {
  name: 'Survey_SlideMessage',
  components: {
    ButtonRouter,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
};
