//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonAction from '@/shared/components/ButtonAction.vue';
import ButtonLink from '@/shared/components/ButtonLink.vue';

export default {
  name: 'BetaModal',
  components: {
    ButtonAction,
    ButtonLink,
  },
  mounted() {
    this.$modal.show('BetaModal');

    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.handleHide();
      }
    })
  },
  methods: {
    handleHide() {
      this.$modal.hide('BetaModal');
      this.$gen2Api.decline(this.$store.getters.confirmation.email);
      this.$analytics.trackEvent('Gen2 Beta Declined', {
        orderNumber: this.$store.getters.confirmation.orderNumber,
        email: this.$store.getters.confirmation.email,
      });
    },
  },
};
