//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import colors from '@/shared/assets/scss/colorsExport.scss';
import GoalDonut from '@/shared/components/GoalDonut.vue';

export default {
  name: 'Survey_Progress',
  components: {
    GoalDonut,
  },
  data: () => ({
    colors,
  }),
  computed: {
    ...mapGetters(['surveyCurrentSlide', 'surveyCurrentPos']),
    total() {
      return this.evaluateDeepness(this.surveyCurrentSlide, this.surveyCurrentPos) * 10;
    },
    donutValue() {
      return Math.floor(((this.surveyCurrentPos) * 10));
    },
    percent() {
      return Math.floor(this.donutValue / this.total * 100);
    },
  },

  methods: {
    evaluateDeepness(obj, current = 0) {
      if (obj.followup) {
        return this.evaluateDeepness(obj.followup, current + 1);
      }
      if (obj.options) {
        return obj.options.reduce((acc, option) => {
          if (option.followup) {
            const test = this.evaluateDeepness(option.followup, current + 1);
            return (acc > test) ? acc : test;
          }
          return acc;
        }, current + 1);
      }
      if (obj.type !== 'thankyou') {
        return current + 1;
      }
      return current;
    },
  },

};
