//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import FCInput from '@/shared/components/FCInput.vue';
import ButtonAction from '@/shared/components/ButtonAction.vue';
import SurveySlide from './Survey_Slide.vue';
import randomizeOptions from '../../utils/surveryHelpers';

export default {
  name: 'Survey_SlideMultiSelect',
  components: {
    FCInput,
    ButtonAction,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      input: '',
      selectedIds: [],
      showFollowup: false,
      followupData: {},
      maxOptions: this.slide.maxOptions || 3,
    };
  },
  computed: {
    canSubmit() {
      return this.selectedIds.length > 0;
    },
    getSurveryOptions() {
      return randomizeOptions(this.slide);
    },
  },
  methods: {
    // filter out
    ...mapActions(['createSurveyEvent']),
    followupSubmitted(option) {
      this.showFollowup = false;
      const id = `${option.id}: ${this.input}`;
      this.selectedIds.push(id);
      this.answerSubmitted(id);
    },
    optionClicked(option) {
      const { id, inlineFollowup } = option;
      if (inlineFollowup) {
        this.showFollowup = !this.showFollowup;
        this.followupData = {
          label: inlineFollowup.label,
          id,
        };
        return;
      }
      this.selectedIds.push(id);
      this.answerSubmitted(id);
    },
    answerSubmitted(id) {
      // Extend this if answers need to do more than track heap event
      this.createSurveyEvent({
        value: id,
        survey: this.slide.id,
        attribution: this.slide.attribution,
      });
      if (this.selectedIds.length === this.maxOptions) {
        this.onSubmit();
      }
    },
    getButtonClass(option) {
      const { id, inlineFollowup } = option;
      const prefix = 'survey-slideselect-option';
      return {
        [`${prefix}`]: true,
        [`${prefix}-selected`]: this.selectedIds.find(selectedId => selectedId.indexOf(id) !== -1),
        [`${prefix}-highlighted`]: inlineFollowup && this.showFollowup,
      };
    },
    getEventData() {
      return {
        id: `${this.slide.bypassId}`,
        others: this.slide.others,
        attribution: this.slide.attribution,
      };
    },
    onSubmit() {
      this.$emit('onSubmit', this.getEventData());
    },
    created() {
      console.log(this.slide.options);
    },
  },
};
