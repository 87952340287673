//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Cart from '@/shared/components/cart/CartFrame.vue';
import Survey from '@/shared/components/survey/Survey.vue';
import heardAboutUs from '@/shared/datasets/hdyhau';
import Steps from './Confirmation_Steps.vue';
import Support from './Confirmation_Support.vue';
import SubUpgrade from './Confirmation_SubUpgrade.vue';

const isShopifyOn = process.env.NUXT_ENV_SHOPIFY_ON === 'true' || process.env.VUE_APP_SHOPIFY_ON === 'true';

export default {
  name: 'Confirmation',
  components: {
    Cart,
    Survey,
    Steps,
    Support,
    SubUpgrade,
  },
  props: {
    url: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    hdyhau: heardAboutUs,
    showSubsContent: false,
    showSteps: false,
    isShopifyOn,
  }),
  computed: {
    ...mapGetters(['confirmation', 'order', 'productsCollection', 'getProductVariantById']),
    cartShopifyProducts() {
      const { cart } = this.confirmation || {};
      return cart.map(item =>  this.getProductVariantById(item.id) ?? {});
    },
    isPos() {
      return this.$store.state.app.location === 'InsideSales';
    },
    hasGQProduct() {
      return !!this.confirmation.cart.find(item => item.id === 'gq_fightcamp_trackers_S' || item.id === 'gq_fightcamp_trackers_L' || item.id === 'pt_fightcamp_trackers_S' || item.id === 'pt_fightcamp_trackers_L');
    },
    skipUpgradeForm() {
      return this.hasGQProduct || this.cartShopifyProducts?.find(item => item?.tags?.includes('skip-upgrade-post-purchase'));
    },
    skipSurvey() {
      return this.hasGQProduct ? false : this.cartShopifyProducts?.find(item => item?.tags?.includes('skip-survey-post-purchase'));
    },
    showUpgradeForm() {
      if (this.skipUpgradeForm) {
        return false;
      }

      return !this.surveydone && !this.showSteps && !this.upgradeFormComplete;
    },
    upgradeFormComplete() {
      if (this.skipUpgradeForm) {
        return true;
      }

      return this.$store.state.survey.upgradeFormCompleted;
    },
    surveydone() {
      return this.$store.state.survey.completed || this.skipSurvey;
    },
    upgradeSuccessful() {
      return this.$store.state.survey.upgradeSuccessful;
    },
    orderNumber() {
      return this.confirmation ? this.confirmation.orderNumber : null;
    },
    email() {
      return this.confirmation ? this.confirmation.email : null;
    },
    uid() {
      return this.confirmation ? this.confirmation.uid : null;
    },
    orderId() {
      return this.confirmation ? this.confirmation.orderID : null;
    },
    totalCost() {
      if (!this.order) {
        return 0;
      }
      return this.order.costBreakdown.totalCost / 100;
    },
    cartContent() {
      let { cart, order } = this.confirmation || {}
      const { costBreakdown } = order || {}
      const {
        productCost: subtotal,
        productShipping: shipping,
        productTax: tax,
        discountAmount: coupon,
        totalCost: total,
      } = costBreakdown || {}

      if (this.isShopifyOn && !this.hasGQProduct) {
        cart = cart?.map(item => {
          const productData = this.getProductVariantById(item.id);
          const productVariant = productData?.variants.find(variant => variant?.id === item?.id) || {};
          const title = productVariant?.name === 'Default Title' ? productData?.title : productVariant?.name;

          return {
            ...productData,
            id: productVariant?.uid,
            price: productVariant?.price,
            productName: title,
            description: productData.description,
            qty: item.qty,
          };
        });
      }

      return {
        cart,
        subtotal,
        shipping,
        tax,
        coupon,
        total,
      };
    },
  },
  methods: {
    triggerShowSteps() {
      this.showSteps = true;
    },
  },
  created() {
    this.$store.dispatch('clearCart');

    this.prod = process.env.VUE_APP_ANALYTICS_ENV !== 'dev';
    const { cart, orderNumber, subscription } = this.confirmation || {}

    const found = (cart || []).find(item => item.type === 'subs' || item.type === 'subs_only');

    // TODO show by Shopify tag display-pkg-confirmation-post-purchase instead of subscription in cart
    this.showSubsContent = (found != null) || subscription || this.cartShopifyProducts?.find(item => item?.tags?.includes('display-pkg-confirmation-post-purchase'));

    if (orderNumber && !this.seenConfirmation) {
      this.seenConfirmation = true;
    }

    if (this.showSubsContent) {
      this.$store.commit('SET_ORDER_INFO', {
        email: this.email,
        orderNumber: this.orderNumber, // must be Number or else survey API throws an error
        orderId: this.orderId,
        uid: this.uid,
        skipSurveyEvent: !!this.skipSurvey,
      });
    }
  },
};

