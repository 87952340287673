//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapGetters, mapActions } from 'vuex';
import motivation from '@/shared/datasets/hdyhau/motivation';
import SurveySlideSelect from '@/shared/components/survey/Survey_SlideSelect.vue';
import SurveySlideInput from '@/shared/components/survey/Survey_SlideInput.vue';
import SurveySlideList from '@/shared/components/survey/Survey_SlideList.vue';
import SurveySlideThankYou from '@/shared/components/survey/Survey_SlideThankYou.vue';
import SurveySlideMultiSelect from '@/shared/components/survey/Survey_SlideMultiSelect.vue';
import SurveySlideMessage from '@/shared/components/survey/Survey_SlideMessage.vue';
import SurveyProgress from '@/shared/components/survey/Survey_Progress.vue';
import BetaModal from '@/shared/components/BetaModal.vue';

function evaluateDeepness(obj, current = 0) {
  if (obj.followup) {
    return evaluateDeepness(obj.followup, current + 1);
  }

  if (obj.options) {
    return obj.options.reduce((acc, option) => {
      if (option.followup) {
        const test = evaluateDeepness(option.followup, current + 1);
        return (acc > test) ? acc : test;
      }
      return acc;
    }, current + 1);
  }
  if (obj.type !== 'thankyou') {
    return current + 1;
  }
  return current;
}

export default {
  name: 'Survey',
  components: {
    SurveyProgress,
    BetaModal,
  },
  props: {
    survey: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
    disableSurveyProgressUi: {
      type: Boolean,
      default: false,
    },
    disableLegacyThankYouPage: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    loading: true,
    motivationSurveyCompleted: false,
  }),
  computed: {
    ...mapGetters([
      'surveyCurrentPos',
      'surveySlides',
      'surveyCurrentSlide',
      'surveyCompleted']),

    showMessage() {
      if (this.surveyCurrentSlide) {
        return true;
      }
      return this.surveyCurrentSlide.type !== 'thankyou';
    },
    displayBetaModal() {
    const { $store } = this;
      return $store.state.survey.customerDeclinedBeta === null && $store.getters.qualifiesForBeta && !$store.state.survey.betaMessage;
    },
  },
  watch: {
    surveyCurrentPos(currentPos) {
      if (this.disableSurveyProgressUi) {
        this.$emit('progressChange', Math.floor((currentPos)) / evaluateDeepness(this.surveyCurrentSlide, currentPos));
      }
    },
  },
  methods: {
    ...mapMutations({
      nextSlide: 'GOTO_NEXT_SLIDE',
      completeSurvey: 'COMPLETE_SURVEY',
    }),
    ...mapActions(['createSurvey', 'updateSurvey']),

    getSlideComponent(slide) {
      if (slide.type === 'select') {
        return SurveySlideSelect;
      }

      if (slide.type === 'list') {
        return SurveySlideList;
      }

      if (slide.type === 'thankyou' && !this.$props.disableLegacyThankYouPage) {
        return SurveySlideThankYou;
      }

      if (slide.type === 'thankyou' && this.$props.disableLegacyThankYouPage) {
        return undefined;
      }

      if (slide.type === 'message') {
        return SurveySlideMessage;
      }

      if (slide.type === 'multiselect') {
        return SurveySlideMultiSelect;
      }

      return SurveySlideInput;
    },

    async next(event) {
      const showFollowup = !!event.followup;
      const showMotivationSurvey = !showFollowup && !this.motivationSurveyCompleted;
      const showEnd = !showFollowup && this.motivationSurveyCompleted;

      const sendUpdate = showFollowup || showMotivationSurvey;

      if (showFollowup) {
        this.nextSlide({
          ...event.followup,
          others: event.others,
          attribution: event.attribution,
        });
      }

      if (showMotivationSurvey) {
        this.nextSlide({
          ...motivation,
          bypassId: event.id,
          others: event.others,
          attribution: event.attribution,
        });
        this.motivationSurveyCompleted = true;
      }

      if (showEnd) {
        this.nextSlide({
          id: `${event.id} end`,
          attribution: event.attribution,
          type: 'thankyou',
          question: 'Thank You!',
          label: 'Go to Order',
        });
      }

      if (sendUpdate) {
        let details = event.value ? event.value : (event.others || [])
        try {
          await this.updateSurvey({
            value: event.id,
            details,
            attribution: event.attribution,
            status: showMotivationSurvey ? 'completed' : 'inprogress',
          });
        } catch (err) {
          console.log('error updating survey', err)
        }
      }
    },
  },
  created() {
    this.createSurvey(this.survey)
      .then(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.log('Error creating survey', err)
        this.completeSurvey();
      });
  },
};
