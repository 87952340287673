//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonAction from '@/shared/components/ButtonAction.vue';
import SurveySlide from './Survey_Slide.vue';
import randomizeOptions from '../../utils/surveryHelpers';

export default {
  name: 'Survey_SlideSelect',
  components: {
    ButtonAction,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getSurveryOptions() {
      return randomizeOptions(this.slide);
    },
  },
};
