//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ButtonLink from '@/shared/components/ButtonLink.vue';
import ButtonAction from '@/shared/components/ButtonAction.vue';
import ConfirmationStep from './Confirmation_Step.vue';

const TEN_SECONDS = 10000;

export default {
  name: 'Confirmation_Steps',
  components: {
    ButtonAction,
    ButtonLink,
    ConfirmationStep,
  },
  props: {
    url: {
      type: String,
      default: undefined,
    }
  },
  data: () => ({
    textAppState: 0,
    textPhoneNumber: '',
  }),
  computed: {
    ...mapGetters(['confirmation']),
  },
  created() {
    if (this.confirmation) {
      this.textPhoneNumber = this.confirmation.textPhoneNumber;
    }
  },
  methods: {
    textApp() {
      if (this.textAppState === 0) {
        this.textAppState = 1;
        this.$api_client.textApp(this.textPhoneNumber, this.$props.url)
          .then((rep) => {
            this.textAppState = 2;
          }).catch((err) => {
            this.textAppState = 3;
          });
      } else if (this.textAppState > 1) {
        this.textAppState = 0;
      }
    },
  },
};
