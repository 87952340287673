//
//
//
//
//
//
//
//
//
//
//


import ButtonAction from '@/shared/components/ButtonAction.vue';
import SurveySlide from './Survey_Slide.vue';

export default {
  name: 'Survey_SlideThankYou',
  components: {
    ButtonAction,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
};
