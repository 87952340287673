//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import utils from '@/shared/scripts/utils';
import variants from '@/shared/datasets/variants';
import EventBus from '@/shared/scripts/eventbus';
import { Actions } from '@/shared/store/actionsTypes';
import { couponsForDealItems } from '@/shared/datasets/coupons';

export default {
  name: 'CartFrame',
  props: {
    enableRemove: Boolean,
    enableAffirm: Boolean,
    enablePromo: Boolean,
    cartContent: {
      type: Object,
      default: null,
    },
    isShopifyOn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPromo: false,
    inputcoupon: '',
    error: false,
    showMessage: false,
    validCoupon: {},
  }),
  methods: {
    removeItem(item) {
      this.$store.dispatch('removeItemFromCart', item);
      const fullVariant = variants.find(v => v.id === item.id);
      EventBus.$emit('trackRemoveFromCart', [fullVariant]);
      this.$analytics.productRemoved(fullVariant);
    },
    clearCoupon() {
      this.$analytics.couponRemoved(this.inputcoupon);
      this.$store.dispatch('clearCoupon', this.validCoupon);
      this.inputcoupon = '';
      this.showMessage = false;
      this.validCoupon = {};
    },
    clickApplyCoupon() {
      this.$analytics.couponEntered(this.inputcoupon);
      this.applyCoupon().then((data) => {
        EventBus.$emit('trackPromoCodeAttempted', {
          coupon: this.inputcoupon,
          valid: data.couponValid,
          error: data.error,
        });

        this.$analytics.trackPromoCodeAttempted(
          this.inputcoupon,
          data.couponValid,
          data.error,
        );
        if (!data.error) {
          this.validCoupon = data;
          this.$analytics.couponApplied(this.inputcoupon);
          if (couponsForDealItems.includes(data.description)) {
            this.showMessage = true;
          }
        } else {
          this.$analytics.couponDenied(this.inputcoupon);
        }
      }).catch(() => {});
    },

    applyCoupon() {
      if (this.cartqty === 0) {
        this.$store.commit('updateCouponError', 'At least one item is needed to apply this coupon.');
        return Promise.resolve();
      }

      if (!this.canApplyAccessoryCoupon) {
        this.$store.commit('updateCouponError', 'At least one accessory item is needed to apply this coupon.');
        return Promise.resolve();
      }

      const minAmount = this.accessoryCoupons[this.inputcoupon];

      if (this.accessoryTotal < minAmount) {
        this.$store.commit('updateCouponError', `Accessory total does not meet $${parseFloat(minAmount / 100.0).toFixed(0)} minimum.`);
        return Promise.resolve();
      }

      return this.$store.dispatch('updateCoupon', this.inputcoupon);
    },
    isReferralCode(amount) {
      return amount < 100;
    },
    openMembershipModal() {
      this.$store.dispatch(Actions.OPEN_MODAL, { component: 'membership' });
    },
  },
  computed: {
    ...mapGetters([
      'bundle',
      'processingTotalCost',
      'summary_subtotal',
      'summary_tax',
      'summary_shipping',
      'summary_total',
      'summary_coupon',
      'amountoff',
      'cartCoupon',
      'cartCouponError',
      'cart',
      'order',
      'cartqty',
      'cartSyncCompleted',
      'contactEmail',
      'initStarted',
      'CURRENT_VERSION',
      'membershipTaxes',
      'accessoryTotal',
      'accessoryCoupons',
      'canApplyAccessoryCoupon',
      'hasPackageWithBag',
      'isGQOrder'
    ]),
    computedBundle() {
      if (this.isShopifyOn && !this.isGQOrder) { return this.cartContent.cart;}

      const { cart } = this.cartContent || {};
      return (cart || []).length ? cart.map((item) => {
        const desc = variants.find(product => item.id === product.id);
        desc.id = item.id;
        desc.qty = item.qty;
        desc.type = item.type;
        return desc;
      }) : this.bundle;
    },
    filteredBundle() {
      return this.computedBundle.filter(item => item.type !== 'subs_only');
    },
    promoEnabled() {
      return this.enablePromo && this.contactEmail && this.contactEmail !== '';
    },
    subscription() {
      return this.computedBundle.filter(item => item.type === 'subs_only');
    },
    packageInCart() {
      return !!this.computedBundle.find(item => item.type === 'subs_only');
    },
    isConnect() {
      return this.bundle.find(item => (['connect_V2_L', 'connect_V2_S'].includes(item.id)));
    },
    subtotalLine() {
      if (this.cartContent) {
        return this.cartContent.subtotal;
      }
      return this.summary_subtotal;
    },
    couponAmount() {
      let amount = 0;
      if (this.cartContent && this.cartContent.coupon) {
        amount = Math.abs(this.cartContent.coupon);
      } else if (this.summary_coupon) {
        amount = Math.abs(this.summary_coupon);
      } else if (this.amountoff) {
        amount = Math.abs(this.amountoff);
      }
      return amount;
    },
    couponLine() {
      const amount = this.couponAmount;
      if (amount === 0) {
        return '--';
      }
      if (this.isReferralCode(amount)) {
        return 'APPLIED';
      }
      const displayedAmount = -1 * amount;
      return this.$options.filters.currency(displayedAmount);
    },
    shippingLine() {
      if (this.cartContent) {
        return this.cartContent.shipping;
      }
      return this.summary_shipping;
    },
    taxLine() {
      if (this.cartContent) {
        return this.cartContent.tax;
      }
      return this.summary_tax;
    },
    totalLine() {
      if (this.cartContent) {
        return this.cartContent.total;
      }
      return this.summary_total;
    },
    membershipTaxesPresent() {
      return this.membershipTaxes || this.membershipTaxes === 0;
    },
    membershipAmount() {
      if (this.membershipTaxesPresent) {
        return 39 + this.membershipTaxes;
      }
      return 39;
    },
    taxesText() {
      if (this.membershipTaxes > 0) {
        return 'Local taxes included.';
      }
      return 'Local taxes may apply.';
    },
    displayExcludesText() {
      return !this.order && this.$route.name !== 'shop-confirmation'
    },
  },
  watch: {
    inputcoupon() {
      if (this.inputcoupon) {
        this.inputcoupon = this.inputcoupon.toUpperCase().replace(/\s/g, '');
      }
      this.$store.commit('clearCouponError');
    },
    cartCoupon() {
      this.inputcoupon = this.cartCoupon;
    },
    cart() {
      if (this.cartqty) {
        if (!this.cartCoupon && this.inputcoupon) {
          this.applyCoupon().catch(() => {});
        }
      } else {
        this.$store.dispatch('clearCoupon', null);
      }
    },
  },

  mounted() {
    if (this.cartCoupon) {
      this.showPromo = true;
    }
    this.inputcoupon = this.cartCoupon;
    const queryCoupon = utils.getParameterByName('coupon');
    if (queryCoupon) {
      this.showPromo = true;
      this.inputcoupon = queryCoupon;
      this.applyCoupon().catch(() => {});
    }
  },
};

