import { render, staticRenderFns } from "./GoalDonut.vue?vue&type=template&id=1ab43e75&scoped=true&"
import script from "./GoalDonut.vue?vue&type=script&lang=js&"
export * from "./GoalDonut.vue?vue&type=script&lang=js&"
import style0 from "./GoalDonut.vue?vue&type=style&index=0&id=1ab43e75&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../apps/shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab43e75",
  null
  
)

export default component.exports