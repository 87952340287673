//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCInput from '@/shared/components/FCInput.vue';
import ButtonAction from '@/shared/components/ButtonAction.vue';
import SurveySlide from './Survey_Slide.vue';

export default {
  name: 'Survey_SlideInput',
  components: {
    ButtonAction,
    FCInput,
    SurveySlide,
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    input: '',
    survey_empty: false,
  }),
  computed: {
    lastId() {
      const arr = this.slide.id.split(' ');
      return arr[arr.length - 1];
    },
    others() {
      if (!this.slide.others) {
        return [`${this.lastId}: ${this.input}`];
      }
      return this.slide.others.concat([`${this.lastId}: ${this.input}`]);
    },
    eventData() {
      return {
        id: `${this.slide.id}`,
        others: this.others,
        attribution: this.slide.attribution,
        followup: this.slide.followup,
      };
    },
  },
  methods: {
    activateBounce() {
      this.survey_empty = true;
      setTimeout(() => {
        this.survey_empty = false;
      }, 500);
    },
    submit() {
      if (!this.input) {
        this.activateBounce();
        return;
      }
      this.$emit('onSubmit', this.eventData);
    },

  },
};
