//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Confirmation_Step',
  props: {
    step: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
