//
//
//
//

import { mapGetters } from 'vuex';
import ConfirmationView from '@/shared/components/confirmation/Confirmation.vue';
import { getAppBranchUrl } from '~/store/getters/getAppBranchUrl';

export default {
  name: 'Confirmation',
  components: {
    ConfirmationView,
  },
  computed: {
    ...mapGetters(['qualifiesForBeta']),
    appBranchUrl() {
      return getAppBranchUrl(this.$store);
    },
  },
  beforeRouteEnter(_to, _from, next) {
    if (!this.$store.state.checkout.confirmation) {
      next({
        path: '/shop/',
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    if (!this.$store.state.survey.completed) {
      if (this.$store.state.survey.surveyCurrentPos > 0 && this.$store.getters.surveyCurrentSlide.type !== 'thankyou') {
        this.$store.commit('GOTO_PREVIOUS_SLIDE');
        return next(false);
      }
      this.$store.commit('COMPLETE_SURVEY');
      return next(false);
    }
    if (this.$store.state.checkout.confirmation && !this.qualifiesForBeta) {
      this.$store.commit('setConfirmation', null);
    }
    return next();
  },
};
