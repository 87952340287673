//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FCCard, FCButton } from '@fc/angie-ui';
import { Actions } from '@/shared/store/actionsTypes';
import { MembershipIds } from '@/shared/constants/MembershipIds';

  export default {
    name: 'ConfirmationSubUpgrade',
    components: {
      FCCard,
      FCButton,
      MembershipUpgradeModal: () => import('../MembershipUpgradeModal.vue'),
    },
    computed: {
      customerId() {
        return this.$store.state.checkout.customerId;
      },
      shipping() {
        return this.$store.state.checkout.shipping;
      },
      membershipIds() {
        return MembershipIds;
      }
    },
    methods: {
      setUpgradeFormCompleted() {
        this.$store.commit('COMPLETE_UPGRADE_FORM');
      },
      openMembershipModal(type) {
        this.$store.dispatch(Actions.OPEN_MODAL, { component: 'membershipUpgradeModal', config: [{ type }]});
      },
    }
  }
