//
//
//
//
//
//
//
//
//
//
//
//

const boldRegex = /\*([^*]+)\*/g;
const allCapsRegex = /\^([^*]+)\^/g;

export default {
  name: 'Survey_Slide',
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
    skippable: Boolean,
  },
  computed: {
    formattedQuestion() {
      return this.slide.question
          .replace(boldRegex, '<strong style="text-decoration: underline">$1</strong>')
          .replace(allCapsRegex, '<span style="text-transform: uppercase">$1</span>');
    },
  },
  methods: {
    skip() {
      const id = this.slide.id ? `${this.slide.id} Skip` : 'Skip';
      this.$emit('onSubmit', {
        id,
        attribution: this.slide.attribution || '',
        others: this.slide.others,
        followup: this.slide.followup
      });
    },
  },
};
